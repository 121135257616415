html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

@media print {
  @page {
    margin: 48px;
    display: flex;
  }
  /*body {font-size: 8px}*/
  .noPrint {
    display: none !important; /* !important required to override MUI styles */
  }
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
  .printableTable {
    width: 100% !important;
    height: auto;
    overflow: hidden;
  }
  .printableTable td,
  .printableTable th {
    font-size: 8pt;
  }
  .printableSaU {
    font-size: 10pt;
  }
  .printableSaU hr {
    margin-top: 2px;
  }
  div.page-footer {
    position: fixed;
    z-index: 9;
    bottom: 0;
    width: 100%;
    height: 50px;
    font-size: 15px;
    color: #fff;
    page-break-after: always;
    text-align: center;
  }
  div.page-footer img {
    height: 100%;
    width: auto;
  }
}

@media screen {
  .onlyPrint {
    display: none;
  }
}
